<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.roteiro_envio_material.filtro.codigo')"
      :placeholder="$t('modulos.roteiro_envio_material.filtro.codigo')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataAgendamento"
      :label="$t('modulos.roteiro_envio_material.filtro.data_agendamento')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
    />
    <input-select
      v-model="filtro.situacaoRoteiro"
      :options="opcoes.EnumSituacaoRoteiro"
      :label="$t('modulos.roteiro_envio_material.filtro.situacao')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';

export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tipoEnvio: [],
        EnumSituacaoRoteiro: [],
      },
    };
  },
  async mounted() {
    await this.buscarEnumSituacaoRoteiro();
  },
  methods: {
    buscarEnumSituacaoRoteiro: async function () {
      await EnumeradorService.buscar('EnumSituacaoRoteiro').then((res) => {
        this.opcoes.EnumSituacaoRoteiro = res;
      });
    },
  },
};
</script>
